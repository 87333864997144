import { FunctionComponent } from "react";
import { Button } from "@mui/material";
import styles from "./css/MyCollectionstransfer.module.css";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useLocation,useNavigate} from 'react-router-dom';

export const MyCollectionstransferFail: FunctionComponent = () => {

  const location = useLocation();
  const data:any = location.state;
  const navigate = useNavigate();

  const goback = ()=>{
    return navigate('/')
  }

  return (
    <div className={styles.myCollectionstransferDiv}>
      <div className={styles.frameDiv3}>
        <div className={styles.pAGECONTENTDiv}>
         
          <div className={styles.colDiv1}>
            <img
              alt=""
              src="failed.svg"
            />
            <b className={styles.redemptionSuccessfulB}>{data.data && data.data.message ? ' AWWW, SO SORRY ... '  : `Failed!`}</b>
            <div className={styles.frameDiv4}>
                <span>{data.data && data.data.message ? data.data.message  : `Couldn’t complete your transaction, please try again later`}</span>
            </div>
            <Button
              className={styles.btnTransfer}
              sx={{ 
                width: '100%',
                textTransform: 'unset'
              }}
              variant="contained"
              color="error"
              href="#"
              onClick={() => goback()}
            >
              OK
            </Button>
           
          </div>
        </div>
      </div>
    </div>
  );
};
