import { FunctionComponent, useEffect, useState } from "react";
import styles from "../LandingPage.module.css";
import { useNavigate,useLocation } from "react-router-dom"; 
import Image from "@components/Image";

interface CardProps {
    image?: any;
}

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize]:any = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  
  
export const Card: FunctionComponent<CardProps> = ({image}) => {

    const size = useWindowSize();
    const navigate = useNavigate();
    
    if (!image) return <div/>
    let ImageFilter = [];

    const goToDetails = (item: any)=>{
        navigate('/collections-detail', {
            state: {
                item,
            }
        })
      }

    //check resize card
    if (size.width <= 768) {

        ImageFilter = image.reduce(function(r: any[], v: any, i: number) {
            if (i % 2 === 0) r.push(image.slice(i, i + 2));
            return r;
        }, []);
    }else{

        ImageFilter = image.reduce(function(r: any[], v: any, i: number) {
            if (i % 4 === 0) r.push(image.slice(i, i + 4));
            return r;
        }, []);
    }

    return (
        <div>
            {
                ImageFilter.map((item: any,index: any) => ( 
                    <div className={styles.myCollectionRow01Div} key={index}>
                        <div className={styles.colum12Div}>
                            {    
                                item.map((item: any,index: any) => ( 
                                    <div className={styles.colum2Div} key={index} onClick={()=>goToDetails(item)}>
                                        
                                        <Image  className={styles.rectangleIcon} src={item.image} alt=""/>
                                        <div className={styles.frameDiv3}>
                                            <div className={styles.frameDiv4}>
                                                <b className={styles.myCollectionsB}>
                                                    {item.name}#{item.tokenID}
                                                </b>
                                            </div>
                                        </div>
                                        <div className={styles.frameDiv3}>
                                            <img
                                                className={styles.frameIcon}
                                                alt=""
                                                src="logo-central.svg"
                                            />
                                            <div className={styles.frameDiv4}>
                                                <b className={styles.myCollectionsC}>
                                                Central Department Store
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}

                        </div> 
                    </div>
                ))
            }
        </div>
        
    );


};
