import { FunctionComponent, useEffect, useState } from "react";
import styles from "./Personal.module.css";
import { Avatar } from "@mui/material";
import { slicedAddress } from '@utils/convert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import { makeStyles } from '@mui/styles';

type PersonalType = {
  user?: any
  showProfile?: any
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize]:any = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}


const useStyles = makeStyles({
  root: {
    background: '#d31027',
  }
});


export const Personal: FunctionComponent<PersonalType> = ({user,showProfile}) => {

  const classes = useStyles();
  const [state, setState]:any = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;
  
  const size = useWindowSize();
  let address:any =  (user.wallet && user.wallet.address) ? user.wallet.address:'0x0000000000000000000000000000000000000000'
  if (size.width <= 768) {
    address = slicedAddress(user.wallet.address)
  }

  const copyToClipboard =  ()=>{    
    navigator.clipboard.writeText(`${user.wallet.address}`)
    setState({  ...state, open: true });
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  
  return (
    <div className={styles.titleDiv}>
      <div className={styles.imageDiv}>
        {showProfile ??  <div className={styles.imageDiv1}>
          <div className={styles.frameDiv}>
            <Avatar
              className={styles.imageIcon}
              alt="avatar"
              src={user.avatar_m}
            />
          </div>
          <div className={styles.signDiv}>
            <img className={styles.vectorIcon} alt="" src="vector1.svg" />
          </div>
        </div>}
       
      </div>

      <div className={styles.contentDiv}>
        <b className={styles.annetteBlack}>{user.name}</b>
        <div className={styles.accountID}>
          <span>Wallet Address: {address}  </span>
          <span onClick={() => copyToClipboard()}><ContentCopyIcon fontSize={'small'} /></span>

        </div>
      </div>

      {/* <div className={styles.contentDiv}>
        <div className={styles.accountID}>
          <a href="/rewards" className={styles.redeemCode}> Redeem code ?</a>
        </div>
      </div> */}
  
      
      {/* <div className={styles.likeView}>
        <div className={styles.frameDiv1}>
          <div className={styles.likesDiv}>
            <img className={styles.groupIcon} alt="" src="group-62.svg" />
            <b className={styles.level8B}>Level 8</b>
          </div>
          <div className={styles.itemsDiv}>
            <img className={styles.groupIcon} alt="" src="group-63.svg" />
            <b className={styles.level8B}>150 Items</b>
          </div>
        </div>
      </div> */}
      <Snackbar
          ContentProps={{
            classes: {
              root: classes.root
            }
          }}
          autoHideDuration={1500}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message="Copied..."
          key={vertical + horizontal}
        />

    </div>
  );
};
