/* eslint-disable max-len */
import React,{Fragment,useEffect, useState} from 'react'
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useOAuth } from "@services/Oauth";
import Loader from "@components/Loader";
import queryString from 'query-string'

//screen
import Layout from '@components/Layout/Layout';
import Home from '@pages/Home/Home'
import Login from '@pages/Login'

import {MyCollectionstransfer} from '@pages/MyCollection/MyCollectionstransfer'
import {MyCollectionsDetails} from '@pages/MyCollection/MyCollectionsDetails'
import {MyCollectionsItemDetails} from '@pages/MyCollection/MyCollectionsItemDetails'
import {MyCollectionstransferFail} from '@pages/MyCollection/MyCollectionstransferFail'
import {MyCollectionsLists} from '@pages/MyCollection/MyCollectionsLists'

import { Profile } from "@pages/profiles/Profile";

import { ProfileLogOut } from "./ProfileLogOut";

import { RewardsHistoryDetail } from "./Rewards/RewardsHistoryDetail";
import { RewardsHistory } from "./Rewards/RewardsHistory";
import { RewardsStatus } from "./Rewards/RewardsStatus";
import { RewardsStatusFail } from "./Rewards/RewardsStatusFail";

import { Rewards } from "./Rewards/Rewards";

import { Policy } from "./Policy/Policy";
//state
import { useRecoilValue } from 'recoil';
import { authAtom } from '../state';
import axios from 'axios';

function Router() {
  const { isLoading } = useOAuth();

  if (isLoading) return <Loader/>

  return (
      <Layout>
          <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/profile" element={<Profile />} />

              <Route path="/collection" element={<MyCollectionsLists />} />
              
              <Route path="/collections-detail" element={<MyCollectionsDetails />} />
              <Route path="/collections-transfer" element={<MyCollectionsItemDetails />} />
              <Route path="/collection-status" element={<MyCollectionstransfer />} />
              <Route path="/collection-status-fail" element={<MyCollectionstransferFail />} />

              <Route path="/open-status" element={<MyCollectionstransfer />} />
              <Route path="/open-status-fail" element={<MyCollectionstransferFail />} />

              <Route path="/logout" element={<ProfileLogOut />} />


              {/* <Route path="/profile" element={<ProfileNew />} /> */}
              {/* <Route path="/news" element={<LastestNews />} />
              <Route path="/news-details" element={<NewDetails />} />
              
            
              <Route path="/recently" element={<Recently />} />
              <Route path="/recently-detal" element={<RecentlyDetail />} /> */}


              <Route path="/redeem-detail" element={<RewardsHistoryDetail />} />
              <Route path="/redeem-history" element={<RewardsHistory />} />
              <Route path="/redeem-status" element={<RewardsStatus />} />
              <Route path="/redeem-status-fail" element={<RewardsStatusFail />} />
              <Route path="/redeem" element={<Rewards />} />
              
              <Route path="/policy" element={<Policy />} />

              
          </Routes>
      </Layout>
    )
}

function RouterAuth() {

  return (
      <Layout>
          <Routes>
              <Route path="/" element={<ProfileLogOut />} />
          </Routes>
      </Layout>
    )
}


const Index = () => {
  const { verifyAuthCode,getUserInfo } = useOAuth();
  const location = useLocation();
  const auth = useRecoilValue(authAtom);
  // const { redirectTo } = queryString.parse(location.search);
  const {state,code,redirectTo} = queryString.parse(window.location.search);
  
  useEffect(() => {
    const verify = async (code: string | (string | null)[])=>{      
      const res = await verifyAuthCode(code);
      if (res) return window.location.href = '/';
    }

    if (state === 'example_verify'  && code) {
      verify(code)
    }

    if (auth){
      getUserInfo(auth)
    }

    return () => {}
  }, [auth])

  
  
  if (redirectTo === 'logout') return <RouterAuth/>

  if (auth){
    return <Router />
  }else{
    return <Login code={code}/>
  }

};

export default Index;
