/* eslint-disable jsx-a11y/anchor-is-valid */
import { Personal } from "@components/Personal";
import { FunctionComponent } from "react";
// import styles from "./Profile.module.css";
import { useRecoilValue } from "recoil";
import { usersAtom } from "../../../src/state";
import { useOAuth } from "@services/Oauth";
import styles from "./ProfileNew.module.css";
import packageJson from '../../../package.json';
import ArrowForwardIosIcon  from '@mui/icons-material/ArrowForwardIos';
import Loader from "@components/Loader";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

export const Profile: FunctionComponent = () => {
  
  const navigate = useNavigate();
  const user:any = useRecoilValue(usersAtom);
  const { logout } = useOAuth();

  const  gotoHistory = ()=>{
    navigate('/redeem-history')
  }
  const  gotoPolicy = ()=>{
    navigate('/policy')
  }

  if (!user) return <Loader/>
  
  return (
    <div className={styles.profileNewDiv}>
      <Personal user={user} showProfile={false}/>
      <div className={styles.frameDiv}>
        <div className={styles.frameDiv4}>
          <div className={styles.frameDiv5}>
            <div className={styles.frameDiv6}>
              <div className={styles.cardActivityDiv1}   onClick={gotoHistory}>
              
                <div className={styles.titleDiv}>
                  <div className={styles.frameDiv7}>
                    <b className={styles.annetteBlack}>History</b>
                  </div>
                </div>
                <div className={styles.frameDiv12} >
                  <ArrowForwardIosIcon fontSize={'small'} />
                </div>
              </div>
              {/* <div className={styles.cardActivityDiv1}>
              
                <div className={styles.titleDiv}>
                  <div className={styles.frameDiv9}>
                    <b className={styles.annetteBlack}>Help Centre</b>
                  </div>
                </div>
                <div className={styles.frameDiv12}>
                  <ArrowForwardIosIcon fontSize={'small'} />
                </div>
              </div> */}
              <div className={styles.cardActivityDiv1}  onClick={gotoPolicy}>
                <div className={styles.titleDiv}>
                  <div className={styles.frameDiv11}>
                    <b className={styles.annetteBlack}>Privacy Policy</b>
                  </div>
                  
                </div>
                <div className={styles.frameDiv12}>
                  <ArrowForwardIosIcon fontSize={'small'} />
                </div>
              </div>
              <div className={styles.cardActivityDiv1}>
                <div className={styles.titleDiv}>
                  <div className={styles.frameDiv7}>
                    <b className={styles.annetteBlack}>Version </b>
                  </div>
                </div>
                <div className={styles.frameDiv12}>
                {packageJson.version}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameDiv14}>
           <Button
                className={styles.confirmSignOutDiv}
                sx={{ 
                  width: '100%',
                  textTransform: 'unset'
                }}
                variant="contained"
                color="error"
                onClick={logout}
              >
                SIGN OUT
            </Button>
            {/* <div className={styles.confirmSignOutDiv} onClick={logout}>
              <b className={styles.signOutB}>Sign Out</b>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
