import { FunctionComponent } from "react";
import { Button } from "@mui/material";
import styles from "./css/MyCollectionstransfer.module.css";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useLocation } from "react-router-dom";

export const MyCollectionstransfer: FunctionComponent = () => {

  const location = useLocation();
  const data:any = location.state;

  return (
    <div className={styles.myCollectionstransferDiv}>
     
      <div className={styles.frameDiv3}>
        <div className={styles.pAGECONTENTDiv}>
       
          <div className={styles.colDiv1}>
            <img
              alt=""
              src="success.svg"
            />
            <b className={styles.redemptionSuccessfulB}> {data.data && data.data.message ? 'CONGRATULATIONS!' : 'Successful!' } </b>
            <div className={styles.frameDiv4}>
              <div className={styles.transferDiv}>
                <div className={styles.groupDiv}>
                {/* <div
                    className={styles.addressTransferDiv}>Congratulations!</div> */}
                  <div className={styles.addressTransferDiv}>
                  {data.data && data.data.message ? data.data.message : 'Transaction Complete' } 
                  </div>
                 
                </div>
              </div>
              {/* <div className={styles.transferDiv1}>
                <div className={styles.groupDiv1}>
                <div className={styles.transactionHashDiv}>
                    Transaction Hash
                  </div>
                  <div className={styles.a8ef1a4ae540fba76900fd02ee41d2Div}>
                    9a8ef1a4ae540fba76900fd02ee41d239f03fbcd857b8e8ce2eaf5e64cb0fd7e
                    <ContentCopyIcon fontSize={'small'}/>
                  </div>
                
                </div>
              </div> */}
            </div>
            <Button
              className={styles.btnTransfer}
              sx={{ 
                width: '100%',
                textTransform: 'unset'
              }}
              variant="contained"
              color="error"
              href="/"
            >
              OK
            </Button>
           
          </div>
        </div>
      </div>
    </div>
  );
};
