import { FunctionComponent } from "react";
import styles from "./css/Policy.module.css";
// import { Button } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from "react-router-dom";

export const Policy: FunctionComponent = () => {

    const navigate = useNavigate();
    
  return (
    <div className={styles.rewardsHistoryDiv}>
       <div className={styles.frameDiv1}>
            <div className={styles.frameDiv1}>
              <div className={styles.myCollectionTransferItem}>
                <div className={styles.myCollectionTransferItem}>
                  <div className={styles.pAGETITLEDiv1} onClick={()=>navigate(-1)}>
                    <ChevronLeftIcon/>
                    <b className={styles.redeemHistoryB}>  Privacy Policy</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className={styles.frameDiv}>
         
          <div className={styles.frameDiv3}>

            <p>Last Update 9 March 2021</p>
            <dl>
                <dt>
                  Cenergy Innovation Limited (the “Company,” “we,” “us,” or “our”) recognizes the importance of the
                  protection of personal data for you of our products and services. We know you care how information about
                  you is collected, used, disclosed, and transferred outside of Thailand. The information you share with us
                  allows us to provide the products and services you need and want appropriately tailored for you, not only
                  from us, but also those within Central Group's data ecosystem. We appreciate your trust that we will
                  carefully and sensibly handle your personal data while giving you the very best personalized experience
                  and customer services.
                </dt>

                <dt>
                  This privacy policy (this "Privacy Policy") applies to our retail stores, websites, mobile applications, call
                  center,social networking sites, online communication channels, and other locations where we collect your
                  personal data. However, please read this Privacy Policy in conjunction with the terms and conditions of
                  particular service that you use. (This may be provide separately according to the type of Personal Data that
                  we collect from you)
                </dt>

                <dt>
                For the purpose of this Privacy Policy, “Personal Data” means any information relating to an identified or
identifiable natural person.
                </dt>

                <dt>
                We reserve the right to modify this Privacy Policy from time to time, so please review it frequently to see
when this Privacy Policy was last revised. Any changes to this Privacy Policy will become effective when
we post the revised Privacy Policy on our website or application. We will provide additional notice of
significant updates.In case any modification deprives your rights of sensitive data in relation to this Privacy
Policy, the Company will first obtain your consent, except as otherwise permitted by law.
                </dt>
            </dl>

            <ol>
              <li>
                What Personal Data we collect
                  <dt>
                  We may collect or obtain the following types of information which may include your Personal Data
  directly or indirectly from you or other sources or through our affiliates, subsidiaries, business partners
  or other companies. The specific type of Personal Data collected will depend on the context of your
  interactions with us, and the services or products you need or want from us and companies in Central
  Group's data ecosystem.
                  </dt>
                  <dt>
                  1.1. Personal details, such as title, full name, gender, age, occupation, qualifications, job title, position,
business type, company name, nationality, country of residence, date of birth, marital status, number
of family members and children, ages of children, information on government-issued cards (e.g.,
national identification number, photograph of the national identification, information on the national
identification, control number on the reverse side of the national identification (Laser ID), social
security number, passport number, tax identification number, driver's license details or similar
identifiers), immigration details such as arrival and departure date, signature, voice, voice record,
photograph, facial features for recognition, CCTV records, work place, education, insurance details,
license plate details, house registration, household income,salary and personal income;
                  </dt>
                  <dt>
                  1.2. Contact details,such as postal address, delivery details, billing address,residential address, workplace
address, address shown in the national identification card, telephone number, fax number, email
address, LINE ID, Facebook account, Facebook ID, Google ID, Twitter ID, and other ID from social
networking sites, your contact person's contact details (e.g., telephone number, contact data on any
correspondence (e.g. written communication with you), and any other contact details you provided to us;
                  </dt>

                  <dt>
                  1.3. Membership details, such as account details, member card number, reward points, member ID (e.g.
The 1 member card number, The 1 ID, Siebel ID, member ID, customer ID), member type, customer
type, member join/registration date and month, membership period, bank account and payment details,
service and product applications (e.g. membership application, insurance application);
                  </dt>

                  <dt>
                  1.4. Financial details, such as debit/credit card or bank information, credit/debit card number, credit card
type, issuance/expiration date, cycle cut, account details, bank account details, prompt pay number
payment details and records, your information regarding the risk profile for the business partner, credit
rating and solvency, information in accordance with the declaration of suitability, suitability of
transaction and any other financial details;
                  </dt>
                  <dt>
                  1.5. Transaction details, such as details about payment to and from you, payment date and/or time,
payment amount, details about refund, refund amount, points, date and location of purchase,
purchase/order number, appointment date for service, address/date and time for pick up or delivery,
acknowledgement of receipt, recipient email’s signature, warranty details, complaints and claims,
booking details, rental details, transaction, transaction history, location, transaction status, past sales
transaction, status, transaction status, purchasing behaviour, and any other details of products and
services you have purchased, including but not limited to any information incurring from using of
products or services provided on our platform, such as e-pocket, digital asset, lending product,
insurance product and the product related to wealth management, etc.;
                  </dt>
                  <dt>
                  1.6. Technical details, such as Internet Protocol (IP) address, cookies, media access control (MAC) address,
web beacon, log, device ID (such as international mobile equipment identifier (IMEI), electronic serial
number (ESN), mobile equipment identifier (MEID) and serial number (SN)), device model and type,
formats of software and hardware of the device when it is activated in the system, network, connection
details, access details, single sign-on (SSO), login log, access time and location, time spent on the page,
login data, GPS, latitude, longitude and time spent on each webpage, login information, applications
downloaded on a communication devices, search history, browsing details, browser type and version,
time zone setting and location, browser plug-in types and versions, operating system and platform and
other technology on devices you use to access the platform, including any other technical information
arising from the use of our platform and systems;
                  </dt>
                  <dt>
                  1.7. Behaviour details, such as information about your purchasing behavior and data supplied through the
use of our products and services;
                  </dt>
                  <dt>
                  1.8. Profile details, such as your username and password, profile details and picture, purchases, historical
orders, past orders, purchase history, items bought, item quantity, orders or product recalls made by
you, orders via website, Cash On Delivery details, order ID, financial records, PIN, your interests,
preferences, feedback and survey responses, satisfaction survey, social media engagement,
participation details, loyalty programs, your use of discount codes and promotions, customer order  
3
description, customer service, attendance to trade exhibitions and events, trade exhibitions, litigation,
testing and trials;
                  </dt>
                  <dt>
                  1.9. Usage details, such as information on how you browse or use our websites, platform, application
products and services, productsin customer's cart, wish list record, remind me flash sale record, followshop record, and timestamp of last click and Q&A record;
                  </dt>
                  <dt>
                  1.10.Marketing and communication details, such as your preference in receiving marketing from us, our
affiliates, subsidiaries, business partners or other companies, and your communication preferences;
and/or
                  </dt>
                  <dt>
                  1.11Sensitive data, such as race,religion, political opinions, fingerprints, facial recognition, person identity
information (biometrics), face, information from the iris recognition, physical or mental health or
condition, genetic data, medical history, disability and criminal records.
If you provide Personal Data of any third party to us, e.g., their name, family name, address details, and
telephone number for emergency contact, family member income, or if you use the service on any of
our platforms with your consent, we can access and collect third party personal information relating to
you, such as information on name, picture and/or phone number, as well as personal and contact
information of family, friends, emergency contact persons, recommended persons or referrals
accessible from your mobile number, etc.; please provide this Privacy Policy for their acknowledgement
and/or obtaining consents if necessary.
We will only collect, use, or disclose sensitive data on the basis of your explicit consent or where
permitted by law.
We only collect the information of children, quasi-incompetent persons, and incompetent persons where
their parent or guardian has given their consent. We do not knowingly collect information from
customers under the age of 20 without their parental consent when it is required, or from quasiincompetent persons and incompetent persons without their legal guardian's consent. In the event we
learn that we have unintentionally collected personal information from anyone under the age of 20
without parental consent when it is required, or from quasi-incompetent persons and incompetent
persons without their legal guardians, we will delete it immediately or process only if we can rely on
other legal bases apart from consent.
                  </dt>

              </li>
              <li>
                Why we collect, use or disclose your Personal Data
                <dt>2.1. The purpose for which your consent would be required</dt>
                <dt>Marketing and Communications: We collect, use and disclose your Personal Data to provide
privileges and promotions, discounts, special offers, advertisements, notices,news, information and any
marketing and communications about the products and services from us, Central Group, our affiliates,
subsidiaries and business partners which we cannot rely on other legal bases.</dt>
<dt>2.2. The purposes we may rely on and other legal groundsfor processing your Personal Data</dt>
                <dt>We may also rely on (1) contractual basis, for our initiation or fulfilment of a contract with you; (2) legal
obligation, for the fulfilment of the legal obligations; (3) legitimate interest, for the purpose of our
legitimate interests and the legitimate interests of third parties; (4) vital interest, for preventing or
suppressing a danger to a person’s life, body, or health; and/or(5) public interest, for the performance of
a task carried out in the public interest or for the exercising of official authorities.
We may collect, use and disclose your Personal Data for the following purposes</dt>
<dt>1) To provide products and services to you: To enter into a contract and manage our contractual
relationship with you; to support and perform other activities related to such services or products; to
complete and manage bookings and to carry out financial transaction and services related to the
payments including transaction checks, verification, and cancellation; to process your orders,
delivery, and collections and returns; refund and exchange of products or services; to provide
updates and on the delivery of the products, and to perform warehouse internal activities, including
picking, packing, and labelling of packages; to verify warranty period; to provide aftersales services,
including maintenance and facility reservation;
2) Marketing and Communications: To provide privileges, offers, updates, sales, special offers,
promotions, advertisements, notices, news, information and any marketing and communications
about the products and services from us, Central Group, affiliates, subsidiaries and business partners.
3) Promotions, special offer, loyalty programs, reward programs, prize draws, competitions, and
other offer promotions: To allow you to participate or earn promotions, special offers, loyalty
programs, reward program, sweepstakes, privilege, prize draws, competitions, and other
offer/promotions(e.g., sending you reminder emails and transferring your Personal Data to business
partners)to participate in activities and seminars, and all services related to advertising. This includes
to process and administer your account registration, gift registration, event registration; for
processing, collection, addition, exchange, earning, redemption, payment, and transfer of points; to
examine your entire user history, both online and offline; to provide and issue gift vouchers, gift
cards, and invoices;
4) Registration and Authentication: To register, verify, prove, affirm, identify, and/or authenticate
you or your identity;
5) To manage our relationship with you: To contact and communicate with you as requested by you
or in relation to the products and services you obtain from us, those within Central Group's data
ecosystem, affiliates,subsidiaries and business partners; to handle customer service-related queries,
request, feedback, complains, claims, disputes or indemnity; to provide technical assistance and deal
with technical issues; to process and update your information; to facilitate your use of the products
and services;
6) Personalization, profiling and data analytics: To recommend products and services that might be
of interest to you, identify your preferences and personalize your experience; to learn more about
you, the products and services you receive and other products and services you may be interested in
receiving; to measure your engagement with the products and services, undertake data analytics,
data cleansing, data profiling, market research, surveys, assessments, behaviour, statistics andsegmentation, consumption trends and patterns; profiling based on the processing of your Personal
Data, for instance by looking at the types of products and services that you use, how you like to be
contacted; to know you better; to improve business performance; to better adapt our content to the
identified preferences; to determine the effectiveness of the promotional campaigns, identify and
resolve of issues with existing products and services; qualitative information development. For this
purpose, we will collect, use and disclose your Personal Data for your interest and benefit and for
legitimate interest and businesses of Central Group, affiliates, subsidiaries and our business partners
where such interests and businesses are not overridden by your fundamental rights to personal data.
We will request your consent where consent is required from time to time;7) To improve business operations, products, and services: To evaluate, develop, manage, and
improve, research and develop the services, products, system, and business operations for you and
all of our customers within Central Group's data ecosystem, including but not limited to our business
partners; to identify and resolve issues; to create aggregated and anonymized reports, and measure
the performance of our physical products, digital properties, physical measurement of products
performance, digital features and marketing campaigns as well as developing business models,
model for loan consideration, insurance and debt collection model;
8) To learn more about you: To learn more about the products and services you receive, and other
products and services you may be interested in receiving, including profiling based on the processing
of your Personal Data, for instance by looking at the types of products and services that you use
from us, how you like to be contacted and so on;
9) Functioning of the sites, mobile application, and platform:To administer, operate, track, monitor,
and manage the sites and platform to facilitate and ensure that they function properly, efficiently,
and securely; to facilitate your experience on the sites and platform; improve layout, and content of
the sites and platform;
10) IT Management: For business management purpose including for IT operations, management of
communication system, operation of IT security and IT security audit; internal business management
for internal compliance requirements, policies, and procedures;
11) Compliance with regulatory and compliance obligations:To comply with legal obligations, legal
proceedings, or government authorities' orders which can include orders from government
authorities outside Thailand, and/or cooperate with court, regulators, government authorities, and
law enforcement bodies when we reasonably believe we are legally required to do so, and when
disclosing your Personal Data is strictly necessary to comply with the said legal obligations,
proceedings, or government orders. This includes to provide and handle VAT refund service; issue
tax invoices or full tax forms; record and monitor communications; make disclosures to tax
authorities, financial service regulators, and other regulatory and governmental bodies, and
investigating or preventing crime;
12) Protection of our interests: To protect the security and integrity of our business; to exercise our
rights or protect our interest where it is necessary and lawfully to do so, for example to detect,
prevent, and respond to fraud claims, intellectual property infringement claims, or violations of law;
to manage and prevent loss of our assets and property; to secure the compliance of our terms and
conditions; to detect and prevent misconduct within our premises which includes our use of CCTV;
to follow up on incidents; to prevent and report criminal offences and to protect the security and
integrity of our business;13) Fraud detection: To verify your identity, and to conduct legal and other regulatory compliance
checks (for example, to comply with anti-money laundering regulations, and prevent fraud). This
includes to perform sanction list checking, internal audits and records, asset management, system,
and other business controls;
14) Corporate transaction: in the event of sale, transfer, merger, reorganization, or similar event we
may transfer your Personal Data to one or more third parties as part of that transaction;
15) Risks: To perform risk management, audit performance, and risk assessments; and/or
16) Life: To prevent or suppress a danger to a person’s life, body, or health.
If you fail to provide your Personal Data when requested, we may not be able to provide our products
and services to you. </dt>
</li>

<li>To whom we may disclose or transfer your Personal Data
<dt>We may disclose or transfer your PersonalData to the following third parties who collect, use and disclose
Personal Data in accordance with the purposes under this Privacy Policy. These third parties may be
located in Thailand and areas outside Thailand. You can visit their privacy policies to learn more details
on how they collect, use and disclose your personal data as you could also be subject to their privacy
policies.</dt>
<dt>3.1. The company within Central Group's data ecosystem
As Cenergy Innovation Limited is part of data ecosystem of Central Group which all collaborate and
partially share customer services, products and systems including website-related platforms and systems,
we may need to disclose or transfer your Personal Data to, or otherwise allow access to such Personal
Data by other companies within Central Group's data ecosystem for the purposes set out in this Privacy
Policy. Please see list of companies for further details here [ https://www.centralgroup.com/storage/pdpa/cg-pdpa-brc-intra-group-agreement-en.pdf ]</dt>

<dt>3.2. Our service providers
We may use other companies, agents or contractors to perform services on behalf or to assist with the
provision of products and services to you. We may share your Personal Data to our service providers or
third-party suppliers including, but not limited to (1) infrastructure, internet, infrastructure technical,
software and website, and IT service providers; (2) warehouse and logistic service providers; (3) payment
service providers; (4) research agencies; (5) analytics service providers; (6) survey agencies; (7) auditors; (8)
marketing, advertising media, and communications agencies; (9) call center; (10) campaign and event
organizers; (11) sale representative agencies; (12) telecommunications and communication service
providers; (13) payment, payment system, authentication, and dip chip service providers and agents; (14)
outsourced administrative service providers; (15) data storage and cloud service providers; (16) verifying
and data checking (Netbay and Department of Provincial Administration) service providers; (17)
dispatchers; and/or (18) printing service providers.In the course of providing such services, the service providers may have access to your Personal Data.
However, we will only provide our service providers with the Personal Data that is necessary for them to
perform the services, and we ask them not to use your information for any other purposes. </dt>
<dt>3.3. Our business partners
We may transfer your Personal Data to our business partnersin the businesses of banking, finance, credit,
loan, asset management, investment, insurance, credit cards, telecommunications, marketing, retail, ecommerce, warehouse, logistics, wellness, lifestyle products and services, spa and fitness, reward and
loyalty program, and data analytics, including platform sellers or providers whom we may jointly offer
products or services, or whose products or services may be offered to you. Data shared in this way will
be governed by the third party’s privacy policy and not this Privacy Policy. </dt>
<dt>3.4. Social networking sites
We allow you to login on our sites and platforms without the need to fill out a form. If you log in using
the social network login system, you explicitly authorize us to access and store public data on your social
network accounts(e.g. Facebook, Google, Instagram), as well as other data mentioned during use of such
social network login system. In addition, we may also communicate your email address to social networks
in order to identify whether you are already a user of the concerned social network and in order to post
personalized, relevant adverts on your social network account if appropriate.
We also partner with certain third parties that allow you to enroll in their services or participate in their
promotions. For example, certain companies allow you to use your loyalty program number or online
services login to receive or register for their services. Additionally, your social network account provider
allows you to connect your social network account to your online services account or log into your online
services account from your social network account. When you enroll in those services, we will share your
Personal Data to those third parties. If you do not want to share your Personal Data in this way, do not
provide your loyalty or reward program number to third parties, do not use your online services account
to register for third-party promotions and do not connect your online services account with accounts on
third-party services. Data shared in this way will be governed by the third party’s privacy policy and not
this Privacy Policy. </dt>
<dt>3.5. Third parties required by law
In certain circumstances, we may be required to disclose or share your Personal Data in order to comply
with a legal or regulatory obligations. This includes any law enforcement agency, court, regulator,
government authority or other third party where we believe it is necessary to comply with a legal or
regulatory obligation, or otherwise to protect our rights, the rights of any third party or individuals’
personal safety, or to detect, prevent, or otherwise address fraud, security, or safety issues. </dt>
<dt>3.6. Professional advisors
This includes lawyers, technicians and auditors who assist in running our business, and defending or
bringing any legal claims.</dt>
<dt>3.7. Associations
We may transfer your Personal Data to other member associations, such as Thailand E-Payment
Association (TEPA), Electronic Transactions Development Agency (ETDA), the Association of
Confederation of Consumer Organization, Thailand (ACCOT), Foundation for consumers, the Thai
Chamber of Commerce, Thai E-Commerce Association, Thai Retailers Association, Thai Shopping
Center Association and the Ratchaprasong Intersection Group.</dt>
<dt>3.8 Assignee of rights and/or obligations
Third parties as our assignee, in the event of any reorganization, merger, business transfer, whether in
whole or in part, sale, purchase, joint venture, assignment, transfer or other disposition of all or any
portion of our business, assets or stock or similar transaction; will comply with this Privacy Policy to
respect your Personal Data. </dt>
              </li>
              <li>
              International transfers of your Personal Data
We may disclose or transfer your Personal Data to third parties or servers located overseas, which the
destination countries may or may not have the same equivalent level of protection for Personal Data
protection standards. We take steps and measures to ensure that your Personal Data is securely transferred
and that the receiving parties have in place an appropriate level of protection standards or other
derogations as allowed by laws. We will request your consent where consent to cross-border transfer is
required by law.
              </li>

              <li>How long do we keep your Personal Data
We retain your Personal Data for as long as is reasonably necessary to fulfil purpose for which we
obtained it, and to comply with our legal and regulatory obligations. However, we may have to retain your
Personal Data for a longer duration, as required by applicable law.</li>
<li>Security of your Personal Data
The Company recognizes the importance of maintaining the security of your Personal Data. Therefore,
the Company endeavours to protect your information by establishing security measures for your Personal
Data appropriately and in accordance with the confidentiality safeguard of Personal Data, to prevent loss,
unauthorized or unlawful access, destruction, use, alteration, rectification or disclosure; provided,
however, that the Company will ensure that the method of collecting, storing and processing of your
Personal Data, including physical safety measures follow the information technology security policies
and guidelines of the Company.</li>
<li>Cookies and how they are used
If you visit our websites, we will gather certain information automatically from you by using cookies.
Cookies are small pieces of information or text issued to your computer when you visit a website and are
used to store or track information about your use of a website and used in analyzing trends, administering
our websites, tracking users’ movements around the websites, or to remember users’ settings. Some
9
cookies are strictly necessary because otherwise the site is unable to function properly. Other Cookies
allow us to enhance your browsing experience, tailor content to your preferences, and make your
interactions with the site more convenient: they remember your username in a secure way, as well as
your language preferences.
Most Internet browsers allow you to decide whether or not to accept cookies. If you reject, remove or
block Cookies can affect your user experience and without cookies, your ability to use some or all of the
features or areas of our websites may be limited.
In addition, some third parties may issue Cookies through our websites to serve ads that are relevant to
your interests based on your browsing activities. These third parties may also collect your browser history
or other information to determine how you reached our websites and the pages you visit when you leave
our websites. Information gathered through these automated means may be associated with the Personal
Data you previously submitted on our website.</li>
<li>Your rights as a data subject
  <dt>Subject to applicable laws and exceptions thereof, you may have the following rights to:</dt>
  <ol>
    <li>Access: You may have the right to access or request a copy of the Personal Data we are collecting,
using or disclosing about you. For your own privacy and security, we may require you to prove
your identity before providing the requested information to you. </li>
<li>Rectification: You may have the right to have incomplete, inaccurate, misleading, or or not up-todate Personal Data that we collect, use or disclose about you rectified.</li>

<li>Data Portability: You may have the right to obtain Personal Data we hold about you, in a
structured, electronic format, and to send or transfer such data to another data controller, where this
is (a) Personal Data which you have provided to us, and (b) in the case where we are collecting,
using or disclosing such data on the basis of your consent or to perform a contract with you</li>
<li>Objection:You may have the right to object to certain collection, use or disclosure of your Personal
Data such as objecting to direct marketing. </li>
<li>Restriction: You may have the right to restrict the use of your Personal Data in certain
circumstances. </li>
<li>Withdraw Consent: For the purposes you have consented to our collecting, using or disclosing of
your Personal Data, you have the right to withdraw your consent at any time. </li>
<li>Deletion: You may have the right to request that we delete or de-identity Personal Data that we
collect, use or disclose about you, except we are not obligated to do so if we need to retain such
data in order to comply with a legal obligation or to establish, exercise, or defend legal claims.</li>
<li>Lodge a complaint: You may have the right to lodge a complaint to the competent authority where
you believe our collection, use or disclosure of your Personal Data is unlawful or noncompliant
with applicable data protection law.</li>
    </ol>
</li>
<li>Our Contact Details
  <dt>If you wish to contact us to exercise the rights relating to your Personal Data or if you have any queries
about your Personal Data under this Privacy Policy, please contact us or our Data Protection Officer at:</dt>
<ol>
  <li>Company Name
      <ul>
        <li>Cenergy Innovation Limited</li>
        <li>No.919/111, 919/11, Jewelry Trade Center Building, 54th - 55th Floors, Silom Road, Silom  Sub-district, Bangrak District, Bangkok 10500</li>
        <li>02-034-9999</li>
      </ul>
  </li>
  <li>Data Protection Officer
      <ul>
        <li>Data Protection Officer</li>
        <li>Data Protection Office, Central Group 22 Soi Somkid Ploenchit Road, Lumpini, Pathumwan, Bangkok, 10330 Thailand</li>
        <li>email: dpo@central.co.th</li>
      </ul>
  </li>
</ol>
</li>
            </ol>
     
          </div>
          

      </div>
    
    </div>
  );
};
