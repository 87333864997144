import { FunctionComponent } from "react";
import styles from "../css/ProfileLogOut.module.css";
import { Button } from "@mui/material";
export const ProfileLogOut: FunctionComponent = () => {
  return (
    <div className={styles.profileLogOutDiv}>
      <div className={styles.frameDiv2}>
        <div className={styles.frameDiv3}>
          <div className={styles.myCollectionTitleDiv}>
            <div className={styles.titleContainerDiv}>
              <div className={styles.titleDiv}>
                <b className={styles.loggedOutB}>LOG OUT</b>
              </div>
            </div>
            <div className={styles.borderDiv} />
          </div>
        </div>
        <div className={styles.frameDiv4}>
          <div className={styles.colDiv}>
            <div className={styles.frameDiv5}>
              <b
                className={styles.youHaveSuccessfullyLoggedO}
              >{`You have successfully logged out `}</b>
            </div>
            <div className={styles.frameDiv6}>
              <div className={styles.frameDiv7}>
                <div className={styles.transferDiv}>
                  <div className={styles.groupDiv}>
                    <div className={styles.tapSignInButtonToStayL}>
                      Tap “SIGN IN”  button to login again
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
       
      </div>
      <div className={styles.frameDiv8}>
            <Button
                className={styles.confirmSignInDiv}
                sx={{ 
                  width: '100%',
                  textTransform: 'unset'
                }}
                variant="contained"
                color="error"
                // href="/my-collectionsitem-details"
                onClick={()=> window.location.replace('/')}
              >
                SIGN IN
            </Button>
      </div>
    </div>
  );
};
