import { FunctionComponent } from "react";
import styles from "./css/MyCollectionsLists.module.css";
import { Button } from "@mui/material";
import { TilteBar } from "./TilteBar";
import { Personal } from "@components/Personal";
import { useNavigate,useLocation } from "react-router-dom"; 

import { useRecoilValue } from "recoil";
import { usersAtom } from "../../../src/state";
import Loader from "@components/Loader";

export const MyCollectionsLists: FunctionComponent = () => {
  const navigate = useNavigate();
  const user:any = useRecoilValue(usersAtom);

  const goToDetails = ()=>{
    navigate('/my-collectionsdetails')
  }
  
  if (!user) return <Loader/>

  return (

    <div className={styles.myCollectionsLists}>
      <Personal user={user}/>
      <div className={styles.frameDiv}>
          <div className={styles.frameDiv1}>
            <div className={styles.myCollectionTitleDiv}>
              <div className={styles.titleContainerDiv}>
                <div className={styles.titleDiv}>
                  <b className={styles.myCollectionsTitle}>My Collections</b>
                </div>
               
              </div>
              <div className={styles.borderDiv} />
            </div>
          </div>
          <div className={styles.frameDiv2}>
            <div className={styles.myCollectionRow01Div}>
              <div className={styles.colum12Div}>
                <div className={styles.colum2Div} onClick={goToDetails}>
                  <img
                    className={styles.rectangleIcon}
                    alt=""
                    src="rectangle-2322@2x.png"
                  />
                  <div className={styles.frameDiv3}>
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsB}>
                        Arlene Oilpaint #07
                      </b>
                    </div>
                  </div>
                  <div className={styles.frameDiv3}>
                    <img
                      className={styles.frameIcon}
                      alt=""
                      src="Logo-TOPS.svg"
                    />
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsC}>
                        OfficeMate
                      </b>
                    </div>
                  </div>
                </div>
                <div className={styles.colum2Div}>
                  <img
                    className={styles.rectangleIcon}
                    alt=""
                    src="rectangle-2320@2x.png"
                  />
                  <div className={styles.frameDiv3}>
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsB}>
                      Victoria Oilpaint #07
                      </b>
                    </div>
                  </div>
                  <div className={styles.frameDiv3}>
                    <img
                      className={styles.frameIcon}
                      alt=""
                      src="Logo-Supersport.svg"
                    />
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsC}>
                        OfficeMate
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.colum12Div}>
                <div className={styles.colum2Div}>
                  <img
                    className={styles.rectangleIcon}
                    alt=""
                    src="rectangle-2325@2x.png"
                  />
                  <div className={styles.frameDiv3}>
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsB}>
                        Arlene Oilpaint #07
                      </b>
                    </div>
                  </div>
                  <div className={styles.frameDiv3}>
                    <img
                      className={styles.frameIcon}
                      alt=""
                      src="Logo-TOPS.svg"
                    />
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsC}>
                        OfficeMate
                      </b>
                    </div>
                  </div>
                </div>
                <div className={styles.colum2Div}>
                  <img
                    className={styles.rectangleIcon}
                    alt=""
                    src="rectangle-2328@2x.png"
                  />
                  <div className={styles.frameDiv3}>
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsB}>
                      Victoria Oilpaint #07
                      </b>
                    </div>
                  </div>
                  <div className={styles.frameDiv3}>
                    <img
                      className={styles.frameIcon}
                      alt=""
                      src="Logo-Supersport.svg"
                    />
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsC}>
                        OfficeMate
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.myCollectionRow01Div}>
              <div className={styles.colum12Div}>
                <div className={styles.colum2Div}>
                  <img
                    className={styles.rectangleIcon}
                    alt=""
                    src="rectangle-2330@2x.png"
                  />
                  <div className={styles.frameDiv3}>
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsB}>
                        Arlene Oilpaint #07
                      </b>
                    </div>
                  </div>
                  <div className={styles.frameDiv3}>
                    <img
                      className={styles.frameIcon}
                      alt=""
                      src="Logo-TOPS.svg"
                    />
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsC}>
                        OfficeMate
                      </b>
                    </div>
                  </div>
                </div>
                <div className={styles.colum2Div}>
                  <img
                    className={styles.rectangleIcon}
                    alt=""
                    src="rectangle-2331@2x.png"
                  />
                  <div className={styles.frameDiv3}>
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsB}>
                      Victoria Oilpaint #07
                      </b>
                    </div>
                  </div>
                  <div className={styles.frameDiv3}>
                    <img
                      className={styles.frameIcon}
                      alt=""
                      src="Logo-Supersport.svg"
                    />
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsC}>
                        OfficeMate
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.colum12Div}>
                <div className={styles.colum2Div}>
                  <img
                    className={styles.rectangleIcon}
                    alt=""
                    src="rectangle-2344@2x.png"
                  />
                  <div className={styles.frameDiv3}>
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsB}>
                        Arlene Oilpaint #07
                      </b>
                    </div>
                  </div>
                  <div className={styles.frameDiv3}>
                    <img
                      className={styles.frameIcon}
                      alt=""
                      src="Logo-TOPS.svg"
                    />
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsC}>
                        OfficeMate
                      </b>
                    </div>
                  </div>
                </div>
                <div className={styles.colum2Div}>
                  <img
                    className={styles.rectangleIcon}
                    alt=""
                    src="rectangle-2347@2x.png"
                  />
                  <div className={styles.frameDiv3}>
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsB}>
                      Victoria Oilpaint #07
                      </b>
                    </div>
                  </div>
                  <div className={styles.frameDiv3}>
                    <img
                      className={styles.frameIcon}
                      alt=""
                      src="Logo-Supersport.svg"
                    />
                    <div className={styles.frameDiv4}>
                      <b className={styles.myCollectionsC}>
                        OfficeMate
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};
