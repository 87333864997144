import { FunctionComponent, useEffect, useState } from "react";
import { TextField, InputAdornment, Icon, Button } from "@mui/material";
import styles from "./css/MyCollectionsItemDetails.module.css";
import { inputLabelClasses } from "@mui/material/InputLabel";
import {useLocation,useNavigate} from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import Web3 from "web3";
import { useOAuth } from "@services/Oauth";
import { useRecoilValue } from "recoil";
import { usersAtom } from "../../../src/state";
import {ABI,ADDRESS } from '@contracts/NFT'

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { makeStyles } from '@mui/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Image from "@components/Image";
import Loader from "@components/Loader";

const useStyles = makeStyles({
  breadcrumbs: {
    "& .MuiBreadcrumbs-separator": {
      color: "#fff",
      fontSize:'10px'
    },
  
  },

});

export const MyCollectionsItemDetails: FunctionComponent = () => {
  const location = useLocation();
  const { sendTransaction } = useOAuth();
  const user:any = useRecoilValue(usersAtom);
  const navigate = useNavigate();
  const detail:any = location.state;
 
  
  const web3:any =  new Web3('https://rpc.omplatform.com/');
  const contract = new web3.eth.Contract(ABI, ADDRESS)

  const [transferTo, setTransferTo] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [addressError, setAddressError] = useState("");
  const classes = useStyles();

  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    let value = event.target.value.replace(/[^a-zA-Z0-9]+/g, '');
    setError(false)
    if (!value.startsWith('0x')) {
      setError(true)
      setAddressError("Invalid Wallet Address")  
    }else{
      setAddressError("")
    }
    setTransferTo(value);
   
  };
  
  
  const Transaction = async () =>{

      setLoading(true);
      if (!transferTo || error){
        setError(true)
        setLoading(false);
        return false;
      }
    
      //check len
      if (transferTo.length < 42){
        setAddressError("Invalid Wallet Address")
        setError(true)
        setLoading(false);
        return false;
      }
      // const ddd = await web3.toBigNumber(transferTo).isZero()
      const emptyAddress = /^0x0{40}$/.test(transferTo);

      if (emptyAddress){
        setAddressError("Invalid Wallet Address")  
        setError(true)
        setLoading(false);
        return false;
      }

      

      

      const nonce   = await web3.eth.getTransactionCount( user.wallet.address, 'pending' )
      const item = [
          {
            "nonce": nonce,
            "from": user.wallet.address,
            "to": detail.item.contractAddress,
            "gasPrice": web3.utils.toHex(web3.utils.toWei('1', 'gwei')),
            "gas": web3.utils.toHex(web3.utils.toWei('200000', 'wei')),
            "value": web3.utils.toHex(web3.utils.toWei('0', 'wei')),
            "data": contract.methods.safeTransferFrom(user.wallet.address, transferTo, detail.item.tokenID).encodeABI(),
          }
      ];
      //transfer nft
      const res = await sendTransaction(item) 

      if(res){
      
        setTimeout(function() {
          setLoading(false);
          return navigate('/collection-status', {
            state: {
              res,
            },
            replace: true 
          });
        }, 3000);
      }else{
      
        setTimeout(function() {
          setLoading(false);
          return navigate('/collection-status-fail', {
            state: {
              res,
            },
            replace: true 
          });
        }, 3000);
      }
      
  }

  if (loading) return <Loader/>

  const att = detail.item.attributes;
  var item_type = att.filter(function(item:any) {
    return item.trait_type === "Type";
  });

  var item_level = att.filter(function(item:any) {
    return item.trait_type === "Level";
  });


  function renderImage () {
    if(item_level[0].value === 'Normal') {
      return (
        <Image  className={`${styles.itemImageIcon}`} src={detail.item.image} alt=""/>
      );
    } else  if(item_level[0].value === 'rare' || item_level[0].value === 'Rare') {
      return (
        <Image  className={`${styles.itemImageIcon}`} src={detail.item.image} alt=""/>
    );
    } else {
      return (
        <Image  className={`${styles.itemImageIcon}`} src={detail.item.image} alt=""/>
      );
    }
  }
   
  return (
    <div className={styles.myCollectionsItemDetails}>
      <div className={styles.pAGETITLEDiv}>
        <div className={styles.pAGETITLEDiv1}>
          <span className={styles.BackHistory} onClick={() => navigate(-1)}>  
            <ChevronLeftIcon/>
            <b className={styles.transferItemB}>Transfer Item</b>
          </span>
     
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs} separator={<KeyboardArrowRightIcon fontSize={'small'}/>}>
              <Link underline="hover" color="white" onClick={() => navigate("/")} fontSize={12}>
                Home
              </Link>
              <Link
                underline="hover"
                color="white"
                onClick={() => navigate(-1)}
                fontSize={12}
              >
                {detail.item.name}#{detail.item.tokenID}
              </Link>
              <Typography color="white" fontSize={12}>Transfer Item</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className={styles.frameDiv3}>
        <div className={styles.pAGECONTENTDiv}>
          <div className={styles.colDiv}>
            {renderImage()}
            
          </div>
          <div className={styles.colDiv1}>
            <b className={styles.victoriaOilpaint07}>{detail.item.name}#{detail.item.tokenID}</b>
          
            <div className={styles.transferDiv}>
              <div className={styles.textWallet}>Wallet Address:</div>
              <TextField
                error={error}
                autoComplete="off"
                hiddenLabel
                fullWidth
                className={styles.frameTextField}
                color="error"
                variant="filled"
                type="text"
                value={transferTo}
                onChange={handleChange}
                helperText={addressError !== "" ? addressError:''}
                // size="medium"
                // margin="none"
                InputLabelProps={{
                  sx: {
                    // set the color of the label when not shrinked
                    color: "grey",
                    [`&.${inputLabelClasses.shrink}`]: {
                      // set the color of the label when shrinked (usually when the TextField is focused)
                      color: "grey"
                    }
                  }
                }}
              />
               <div className={styles.textVertify}>Please verify you have entered the correct recipient address. </div>
            </div>
          
            <LoadingButton
              loading={loading}
              className={styles.btnTransfer}
              sx={{ 
                width: '100%',
                textTransform: 'unset'
              }}
              variant="contained"
              color="error"
              // href="/collection-status"
              onClick={Transaction}
            >
              TRANSFER
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
};
