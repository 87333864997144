import { FunctionComponent,SetStateAction,useEffect,useMemo,useState } from "react";
import styles from "./css/RewardsHistory.module.css";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { slicedAddress } from '@utils/convert';
import Pagination from '@mui/material/Pagination';
import { makeStyles } from '@mui/styles';
import {useLocation,useNavigate} from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from 'axios';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import { usersAtom } from "../../../src/state";

import { useRecoilValue } from "recoil";
import moment from "moment";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const useStyles = makeStyles({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#fff",
      '&.Mui-selected': {
        background: '#d31027',
        color: 'white',

      },
    },
  
  },
  tab: {
   
    "& .MuiTab-root": {
      color: '#fff'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#d31027',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
  root: {
    background: '#d31027',
  }
});


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const PER_PAGE = 5;

export const RewardsHistory: FunctionComponent = () => {

  const classes = useStyles();
  const [page, setPage] = useState(1);
  const user:any = useRecoilValue(usersAtom);
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);


  const [state, setState]:any = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;


  const copyToClipboard =  (hash: any)=>{    
    navigator.clipboard.writeText(`${hash}`)
    setState({  ...state, open: true });
  }

  useEffect(() => {

    const getHistory = async () =>{  
      const result = await axios.get(`https://cnx-explorer-ccoin.leafbot.io/api?module=account&action=txlist&address=${user.wallet.address}`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(function (response) {
        const data =  response.data.result;
        setHistory(data);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    if (user) getHistory()
    return () => {
      
    }
  }, [user])


  const handleChange = (event: any, value: SetStateAction<number>) => {
    setCurrentPage(value);
  };
  
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  if (!history) return <div/>

  // const count = Math.ceil(history.length / PER_PAGE);
  // const indexOfLast = currentPage * PER_PAGE;
  // const indexOfFirst = indexOfLast - PER_PAGE;
  // const currentTodos = history.slice(indexOfFirst, indexOfLast);

  return (
    <div className={styles.rewardsHistoryDiv}>
       <div className={styles.frameDiv1}>
            <div className={styles.frameDiv1}>
              <div className={styles.myCollectionTransferItem}>
                <div className={styles.myCollectionTransferItem}>
                  <div className={styles.pAGETITLEDiv1} onClick={()=>navigate(-1)}>
                    <ChevronLeftIcon/>
                    <b className={styles.redeemHistoryB}>  History</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className={styles.frameDiv}>
         
          <div className={styles.frameDiv3}>
            {
              history.map((item: any,index: any) => ( 
                <div className={styles.frameDiv4} key={index}>
                  <div className={styles.frameDiv5}>
                    <div className={styles.listHistory}>
      
                      <div className={styles.frameDiv6}>
                        <div className={styles.frameDiv7}>
                          <div className={styles.dateTime}>{`Date & Time:`}</div>
                          <div className={styles.div}>{ moment.unix(item.timeStamp).format('y-MM-D H:m:s') }</div>
                        </div>
                        <div className={styles.frameDiv7}>
                          <div className={styles.redeemCodeDiv}>Transaction ID:</div>
                          <div className={styles.frameDiv9}>
                            <div className={styles.div}>{slicedAddress(item.hash)}</div>
                            <span onClick={() => copyToClipboard(item.hash)}>&nbsp;&nbsp;<ContentCopyIcon fontSize={'small'} /></span>
                          </div>
                        </div>
                        <div className={styles.frameDiv7}>
                          <div className={styles.redeemCodeDiv}>Status:</div>
                          <div className={styles.frameDiv9}>
                            <div className={ item.isError === '1' ? styles.divFailed : styles.divSuccess }>{item.isError === '1' ? 'Faild':'Success'}</div>
                          </div> 
                        </div>
                      </div>
                    </div>
                      <div className={styles.borderDiv} />
                  </div>
                </div>
              ))
            }
          </div>

      </div>
      {/* <div className={styles.frameDiv64}>
        <Pagination count={count} page={currentPage} onChange={handleChange} siblingCount={0} boundaryCount={1} classes={{ ul: classes.ul }} color={'primary'} />
      </div> */}

      <Snackbar
          ContentProps={{
            classes: {
              root: classes.root
            }
          }}
          autoHideDuration={1500}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message="Copied..."
          key={vertical + horizontal}
        />
    </div>
  );
};
