
import React, { useEffect } from "react"
import {Header} from "./Header"
import {Footer} from "./Footer"
import styles from "./Layout.module.css";

type Props = {
  children?: React.ReactNode
};

const Layout: React.FC<Props> = ({children}) => {

  return (
    <>
      <div className={styles.homeScreenDiv}>
        <Header />
          {children}
        <Footer />
      </div>
    </>
  )
}

export default Layout;