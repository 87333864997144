import { FunctionComponent,useEffect, useState } from "react";

import styles from "../css/CountDown.module.css";

type PersonalType = {
    date?: any
    setOpening? :any
  };

  export const  CountDown: FunctionComponent<PersonalType> =({date,setOpening})=> {

    const calculateTimeLeft = () => {

        const difference = + new Date(parseInt(date)) - +new Date();
        
        let timeLeft:any = {};
    
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor(difference / (1000 * 60 * 60) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
          };
        }
    
        return timeLeft;
      };
    
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
      useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
            setOpening(timeLeft);
        }, 1000);
    })
   
    return (
        <div>
            {timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? (
            <p>
                <span className={styles.textSize}>{timeLeft.days}</span>
                <span className={styles.textSize}> DAYS  </span>
                <span className={styles.textSize}>{timeLeft.hours}</span>
                <span className={styles.textSize}> HOURS </span>
                <span className={styles.textSize}>{timeLeft.minutes}</span>
                <span className={styles.textSize}> MINUTES </span>
                {/* <span>{timeLeft.seconds}</span>
                <span> วินาที </span> */}
            </p>
            ) : (
            <p>OPEN NOW</p>
            )}
        </div>
    )
}

