import { useOAuth } from "@services/Oauth";
import React, { Component, useEffect,useState } from "react";

import Loader from "@components/Loader";

const Login = (props: any) => {
    const [url,setUrl] = useState();
    const {login} = useOAuth();
    useEffect(() => {
        const loadUrl = async()=>{
            const url = await login();
            setUrl(url)
            if (url && !props.code){
                window.location.replace(url)
            }
        }
        loadUrl()
      }, [url])

      
    return (
        <Loader/>
    )
}

export default Login;
