import { Box,CircularProgress } from "@mui/material";

function Loader(){
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            sx={{
                backgroundColor: '#0a0a0a',
            // '&:hover': {
            //     backgroundColor: 'primary.main',
            //     opacity: [0.9, 0.8, 0.7],
            // },
            }}
        >
            <CircularProgress color={'error'}/>
        </Box>
    )
}

export default Loader;