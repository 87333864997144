import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";

import { useEffect } from "react";
import packageJson from '../package.json';
import { RecoilRoot } from 'recoil';
import {OAuthProvider} from "@services/Oauth";

import CacheBuster from 'react-cache-buster';
import Main from "@pages/Main";
import Loader from "@components/Loader";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
console.log(CLIENT_ID);

const env = {
  apiUrl: '/',
  oAuthUrl: 'https://uat-account.central.tech',
  client_id: `${CLIENT_ID}`,
  redirect_uri: `${window.location.origin}/`
}


function App() {

  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const isProduction = process.env.NODE_ENV === 'production';

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    //TODO: Update meta titles and descriptions below
    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);


  useEffect(() => {
    caching()
   
    return () => {}
  }, [])

  const caching= ()=> {
    let version = localStorage.getItem('version');
      if(version !== packageJson.version){
              if('caches' in window){
              caches.keys().then((names) => {
              // Delete all the cache files
              names.forEach(name => {
                  caches.delete(name);
              })
          });
          // Makes sure the page reloads. Changes are only visible after you refresh.
          window.location.reload();
      }

      localStorage.clear();
      localStorage.setItem('version',packageJson.version);
    }
  };

  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
    >
      <RecoilRoot>
        <OAuthProvider env={env}>
          <Main/>
        </OAuthProvider>
      </RecoilRoot>
    </CacheBuster>
  );
}
export default App;
