import { FunctionComponent } from "react";
import styles from "./Footer.module.css";

export const Footer: FunctionComponent = () => {

  return (
    <div className={styles.footerDiv}>
      <div className={styles.frameDiv}>
        <div className={styles.frameDiv1}>
          <div className={styles.centralWalletAllRightsRese}>
            <span>© 2022 Central Retail Digital, All Rights Reserved</span>
            {/* <span className={styles.span}>{` `}</span>
            <span className={styles.centralGroupSpan}>Central Group</span> */}
          </div>
        </div>
        {/* <div className={styles.frameDiv2}>
          <div className={styles.centralWalletAllRightsRese}>
            Contact Us : Centralmetawallet@centralgroup.com
          </div>
        </div> */}
      </div>
    </div>
  );
  
};
