import { FunctionComponent, useRef,useState ,useEffect} from "react";
import { Button } from "@mui/material";
import styles from "./css/MyCollectionsDetails.module.css";
import { slicedAddress } from '@utils/convert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useLocation,useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { makeStyles } from '@mui/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { authAtom } from "../../../src/state";
import Badge from '@mui/material/Badge';
import Image from "@components/Image";

import Countdown from 'react-countdown';
import Loader from "@components/Loader";

import {CountDown} from "@components/CountDown";

import axios from "axios";
import { useRecoilValue } from "recoil";
import { useOAuth } from "@services/Oauth";

const useStyles = makeStyles({
  breadcrumbs: {
    "& .MuiBreadcrumbs-separator": {
      color: "#fff",
      fontSize:'10px'
    },
  
  },
  disabledButton: {
    "&:disabled": {
      backgroundColor: '#6f6a6a'
    }
  },
  badgeNormal: {
    fontSize: 12,
    padding:10,
    textTransform: 'uppercase',
    backgroundColor:'#C3CFE2',
    color:'#000',
    height: 35,
    width:100,
    marginTop:18
  },
  badgeRare: {
    fontSize: 12,
    padding:10,
    textTransform: 'uppercase',
    backgroundColor:'#3498DB',
    color:'#000',
    height: 35,
    width:100,
    marginTop:18
    // textShadow: '0 0 #17fffc, 2px 0px 10px #ffffff91'
  },
  badgeSpeaical: {
    fontSize: 12,
    padding:10,
    textTransform: 'uppercase',
    backgroundColor:'#D1913C',
    color:'#000',
    height: 35,
    width:100,
    marginTop:18
  }

});


export const MyCollectionsDetails: FunctionComponent = () => {

  const { logout } = useOAuth();
  const auth:any = useRecoilValue(authAtom);

  const location = useLocation();
  const detail:any = location.state;
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  // const countRef = useRef(null);
  const [opening, setOpening]:any = useState({
          hours:'0',
          minutes:'0',
          seconds:'0'
  });
  const [timeing, setTimeing]:any = useState(null);

  const att = detail.item.attributes;
  var item_type = att.filter(function(item:any) {
    return item.trait_type === "Type";
  });

  useEffect(() => {
      const initNFT  = async ()  =>{

        const data = {
          address:detail.item.contractAddress,
          id:detail.item.tokenID
        }
        const result = await axios.post('/webhook/active-date',data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${auth.token_type} ${auth.access_token}`
          }
        }).then(function (response) {
          const data = response.data
          setTimeing(data.data.active_date)
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403){
              logout()
            }
          }
        });
      }
    
    if(item_type[0].value === 'Box') initNFT();
  
    return () => {}
  }, [auth])
  
  
  const gotoTransfer = (item: any)=>{
    navigate('/collections-transfer', {
      state: {
          item,
      }
    })
    
  }

  const gotoOpening = async (item: any)=>{

    if (opening.hours || opening.minutes || opening.seconds){
      return false
    }

    setLoading(true);

    const data = {
      contract_address:item.contractAddress,
      token_id:item.tokenID
    }

    const result = await axios.post('/webhook/unbox',data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.token_type} ${auth.access_token}`
      }
    }).then(function (response) {
      const data = response.data
      
      setTimeout(function() {
        setLoading(false);
        return navigate('/open-status', {
          state: {
            data,
          },
          replace: true 
        })
      })
    })
    .catch(function (error) {
      const data = error.response.data
      setTimeout(function() {
        setLoading(false);
        return navigate('/open-status-fail', {
          state: {
            data,
          },
          replace: true 
        })
      }, 3000);
    });
    
  }
  
  if (loading) return <Loader/>

  var item_level = att.filter(function(item:any) {
    return item.trait_type === "Level";
  });




  //render

  function renderBagde () {
    if(item_level[0].value === 'Normal') {
      return (
        // <Badge badgeContent= {item_level[0].value} classes={{ badge: classes.badgeNormal }}/>
        <div className={styles.cardSeller01DivStart}>
          <div className={styles.BadgeLabelNormal}>
            {item_level[0].value}
          </div>
        </div>
      );
    } else  if(item_level[0].value === 'rare' || item_level[0].value === 'Rare') {
      return (
        // <Badge badgeContent= {item_level[0].value} classes={{ badge: classes.badgeRare }}/>
        <div className={styles.cardSeller01DivStart}>
          <div className={styles.BadgeLabelRare}>
            {item_level[0].value}
          </div>
        </div>
    );
    } else {
      return (
        // <Badge badgeContent= {item_level[0].value} classes={{ badge: classes.badgeSpeaical }}/>
        <div className={styles.cardSeller01DivStart}>
                <div className={styles.BadgeLabelSpeical}>
                 {item_level[0].value}
                </div>
              </div>
      );
    }
  }

  function renderImage () {
    if(item_level[0].value === 'Normal') {
      return (
        <Image  className={`${styles.itemImageDiv} ${styles.ImageNormal}`} src={detail.item.image} alt=""/>
      );
    } else  if(item_level[0].value === 'rare' || item_level[0].value === 'Rare') {
      return (
        <Image  className={`${styles.itemImageDiv} ${styles.ImageRare}`} src={detail.item.image} alt=""/>
    );
    } else {
      return (
        <Image  className={`${styles.itemImageDiv} ${styles.ImageSpeacial}`} src={detail.item.image} alt=""/>
      );
    }
  }


  return (
    <div className={styles.myCollectionsDetailsDiv}>
      <div className={styles.pAGETITLEDiv}>
        <div className={styles.pAGETITLEDiv1}>
          <span className={styles.BackHistory} onClick={() => navigate("/")}>  
            <ChevronLeftIcon/>
            <b className={styles.transferItemB}>{detail.item.name}#{detail.item.tokenID}</b>
          </span>
          
        </div>
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs} separator={<KeyboardArrowRightIcon fontSize={'small'}/>}>
              <Link underline="hover" color="white" onClick={() => navigate("/")} fontSize={12}>
                Home
              </Link>
              <Typography color="white" fontSize={12}>{detail.item.name}#{detail.item.tokenID}</Typography>
            </Breadcrumbs>
        </div>
      </div>
      
      <div className={styles.frameDiv3}>
        <div className={styles.pAGECONTENTDiv}>
          <div className={styles.colDiv}>
            {renderImage()}
          </div>

          <div className={styles.colDiv1}>
            <div className={styles.creatorDiv}>
              {/* <div className={styles.cardSeller01Div}>
                {renderBagde()}
              </div> */}
               {renderBagde()}
               {/* <div className={styles.cardSeller01DivStart}>
                <div className={styles.BadgeLabel}>
                 asdsd
                </div>
              </div> */}
              <div className={styles.cardSeller01Div}>
                <div className={styles.imageDiv}>
                  <img
                    className={styles.frameIcon}
                    alt=""
                    src="logo-central.svg"
                  />
                </div>
                <span className={styles.supersportsB}>Central Department Store</span>
                
              </div>
            </div>
            
            <b className={styles.victoriaOilpaint07}>Details:</b>
            <div className={styles.notTheSharpestToolInTheS}>
              {detail.item.description}
            </div>
           
          
            <div className={styles.frameDiv5}>
              {
                (item_type[0].value === 'Box') && timeing ?
                  <Button
                    disabled={(opening.hours || opening.minutes || opening.seconds) ? true:false }
                    className={styles.btnTransfer}
                    classes={{ disabled: classes.disabledButton }}
                    sx={{ 
                      width: 300,
                      textTransform: 'unset'
                    }}
                    variant="contained"
                    color="error"
                    onClick={()=>gotoOpening(detail.item)}
                  >
                    {timeing?<CountDown date={(timeing)} setOpening={setOpening}/>:'...'}
                  </Button> 
                :
                  <div/>
              }

              <Button
                className={styles.btnTransfer}
                sx={{ 
                  width: 300,
                  textTransform: 'unset'
                }}
                variant="contained"
                color="error"
                onClick={()=>gotoTransfer(detail.item)}
              >
                TRANSFER
              </Button>
          
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};
