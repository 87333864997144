import { FunctionComponent } from "react";
import styles from "./css/RewardsHistoryDetail.module.css";
import { Button } from "@mui/material";

export const RewardsHistoryDetail: FunctionComponent = () => {
  return (
    <div className={styles.rewardsHistoryDetail}>
      <div className={styles.pAGETITLEDiv}>
        <div className={styles.pAGETITLEDiv1}>
          <div className={styles.redeemHistoryDiv}>Redeem history</div>
          <div className={styles.breadcumbDiv}>
            <b className={styles.homeB}>Home</b>
            <div className={styles.frameDiv}>
              <img className={styles.vectorIcon} alt="" src="vector21.svg" />
            </div>
            <b className={styles.rewardsRedemptionB}>Rewards Redemption</b>
            <div className={styles.frameDiv}>
              <img className={styles.vectorIcon} alt="" src="vector21.svg" />
            </div>
            <b className={styles.redeemHistoryB}>Redeem History</b>
          </div>
        </div>
      </div>
      <div className={styles.frameDiv2}>
        <div className={styles.pAGECONTENTDiv}>
          <div className={styles.colDiv}>
            <div className={styles.div}>2022-08-02 02:14:10</div>
            <div className={styles.frameDiv3}>
              <div className={styles.transferDiv}>
                <div className={styles.groupDiv}>
                  <b className={styles.x1dDB2C0817daF18632662E71fdD2d}>
                    1x1dDB2C0817daF18632662E71fdD2dbDC0eB3a9Ec
                  </b>
                  <div className={styles.giftCardPromoCode}>
                    Gift Card / Promo Code:
                  </div>
                </div>
              </div>
              <div className={styles.transferDiv1}>
                <div className={styles.groupDiv1}>
                  <b className={styles.a8ef1a4ae540fba76900fd02ee41d2}>
                    9a8ef1a4ae540fba76900fd02ee41d239f03fbcd857b8e8ce2eaf5e64cb0fd7e
                  </b>
                  <div className={styles.claimCodeDiv}>Claim Code:</div>
                </div>
              </div>
            </div>
            {/* <div className={styles.confirmDoneDiv}>
              <div className={styles.redeemHistoryDiv}>Back</div>
            </div> */}
             <Button
              sx={{ width: 300,textTransform: 'unset' }}
              variant="contained"
              color="error"
              href="/rewards-history"
            >
              Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
