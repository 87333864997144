
import { useOAuth } from "@services/Oauth";
import { FunctionComponent,useState,useEffect } from "react";
import { useRecoilValue } from "recoil";
import { usersAtom } from "../../../src/state";
import styles from "./LandingPage.module.css";
import { Button,Divider } from "@mui/material";
import { Personal } from "@components/Personal";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useNavigate,useLocation } from "react-router-dom"; 
import Loader from "@components/Loader";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {Card} from "./elements/Card"
import axios from 'axios';

import Web3 from "web3";
import {ABI,ADDRESS } from '@contracts/NFT'

import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "#1E293B",
      color:"#fff",
    },
    "& .MuiMenuItem-root": {
      fontSize:14
    }
  }
});



const Home = () => {

  const web3:any =  new Web3('https://rpc.omplatform.com/');  
  const user:any = useRecoilValue(usersAtom);

  const navigate = useNavigate();
  const classes = useStyles();
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [Image , setImage] = useState<any[]>([]);
  const [contractAddress , setContractAddress] = useState<any[]>([]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToRewards = ()=>{
    navigate('/redeem')
  }

  useEffect(() => {
    const initContractFetchNFT = async ()=>{
      //init
      await getTokenUserActive();
      setInterval(async () => {
        await getTokenUserActive();
      }, 10000)
    }

    if (user) initContractFetchNFT()
    return () => {
      setImage([])
      setContractAddress([])
    }
  }, [user])

  useEffect(() => {
    const initFetchNFT = async ()=>{

      if (contractAddress){
        contractAddress.forEach(async (item: string) => {
          const contract = await new web3.eth.Contract(ABI, item)
          const result = await contract.methods.walletOfOwner(user.wallet.address).call()    
          if (result){
            result.forEach((item: string) => {
              getNFTMetadata(contract,item)
              
            });
          }
        });
       
      }
    }

    if (contractAddress) initFetchNFT()
    return () => {

    }
  }, [contractAddress])

  
  const getTokenUserActive = async ()=>{
    const result = await axios.get(`https://cnx-explorer-ccoin.leafbot.io/api?module=account&action=tokenlist&address=${user.wallet.address}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(function (response) {
      const data =  response.data.result;

      var contract = data.map(function(item: { contractAddress: any; }) {
        return item.contractAddress;
      });

      setContractAddress(contract)

    })
    .catch(function (error) {
      console.log(error);
    });

  }
  
  async function getNFTMetadata(contract:any,tokenID:string) {
      
      const result = await contract.methods.tokenURI(tokenID).call()

      const ipfsURL = addIPFSProxy(result);
       
      if (ipfsURL){
        try {
          const request:any = new Request(ipfsURL);      
          const response = await fetch(request);
          const metadata = await response.json();
          const image:string = addIPFSProxy(metadata.image);
          const data:any ={
            ...metadata,
            image,
            tokenID,
            contractAddress:contract._address
          }
          
          const res = Image.filter((item) => (item.image === image && item.tokenID === tokenID) ); 
          if (res.length > 0) return true;
        
          
          setImage(old => [...old, data])
       
        } catch (error) {
          console.log(error);
          
        }
      
      }
  }



  function addIPFSProxy(ipfsHash: string) {
    // if (!ipfsHash.startsWith('https://'))  return ipfsHash.replace('https://c-collectible-static.s3.ap-southeast-1.amazonaws.com', '')
    if (!ipfsHash.startsWith('ipfs://'))  return ipfsHash
    // const URL = "https://cloudflare-ipfs.com/ipfs/"
    // const hash = ipfsHash.replace('https://c-collectible-static.s3.ap-southeast-1.amazonaws.com', '')
    // const ipfsURL = URL + hash
    // return ipfsHash
    return '';
  }
  
  if (!user) return <Loader/>
 
  return (
    <>
      {/* <Personal user={user} showProfile={false}/> */}
      <div className={styles.frameDiv5}>
        <img
          className={styles.blackFridayCompositionWithIcon}
          alt=""
          src="black-friday-composition-with-black-gifts.svg"
        />
        <div className={styles.frameDiv6}>
          <div className={styles.frameDiv7}>
            <div className={styles.frameDiv8}>
              <b className={styles.redeemAVoucher}>Redeem Code?</b>
            </div>
            <div className={styles.frameDiv9}>
              <div className={styles.redeemYourBenefitVoucherOn}>
                {/* Redeem your Benefit Voucher on attractive deals */}
              </div>
            </div>
          </div>
          <div className={styles.frameDiv10}>
            <div className={styles.frameDiv8} onClick={goToRewards}>
              <b className={styles.redeemB}>REDEEM</b>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.landingPageDiv}>
        <div className={styles.frameDiv}>
          <div className={styles.frameDiv1}>
            <div className={styles.myCollectionTitleDiv}>
              <div className={styles.titleContainerDiv}>
                <div className={styles.titleDiv}>
                  <b className={styles.myCollectionsTitle}>My Collection</b>
                  <span className={styles.textItem}>&nbsp;({Image.length} items)</span>
                </div>
                  <Button
                    className={styles.seeAllDiv}
                    sx={{ p: 0 }}
                    variant="text"
                    // color="error"
                    onClick={handleClick}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    endIcon={<ExpandMoreIcon />}
                  >
                  All
                </Button>
                <Menu
                  className={classes.menu}
                  sx={{
                    color: 'red'
                  }}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleClose}>All&nbsp;<small>({Image.length} items)</small></MenuItem>
                  {/* <MenuItem onClick={handleClose}>B2S&nbsp;<small>(0 items)</small></MenuItem>
                  <MenuItem onClick={handleClose}>CDS&nbsp;<small>({Image.length} items)</small></MenuItem>
                  <MenuItem onClick={handleClose}>CRG&nbsp;<small>(0 items)</small></MenuItem> */}
                </Menu>
              </div>
              <div className={styles.borderDiv} />
            </div>
          </div>
          <div className={styles.frameDiv2}>
            <Card  image={Image}/>
          </div>
        </div>

      
    </div>
    </>
  );
};

export default Home